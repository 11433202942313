import tookanDms1 from './../../assets/projects_images/tookan-dms-1.png';
import tookanCust1 from './../../assets/projects_images/tookan-customer-app-1.png';
import tookanExt2 from './../../assets/projects_images/tookan-ext-2.png';
import tookanDo1 from './../../assets/projects_images/tookan-do-1.png';
import urlShortener from './../../assets/projects_images/url-shortener-1.jpg';
import urlShortener2 from './../../assets/projects_images/url-shortener-2.png';
import todo1 from './../../assets/projects_images/todo1.jpg';
import todo2 from './../../assets/projects_images/todo2.png';
import covidTracker from './../../assets/projects_images/covid-tracker.png';
import searchApi from './../../assets/projects_images/search-api.png';
import bookStore from './../../assets/projects_images/book-store.jpg';

const projects = [
    {
        id: 1,
        title: 'Tookan Delivery Management System',
        shortDescription: 'Manage all your delivery operations from one platform to enhance efficiency and deliver exceptional customer experience at scale.',
        longDescription: `Managing delivery operations on a single platform can significantly enhance operational efficiency and deliver exceptional customer experiences at scale.
                          Consolidating all delivery operations simplifies processes, reduces errors, and ensures timely delivery of products or services. By using a single platform, 
                          businesses can also better track and monitor deliveries, as well as communicate with customers in real-time, improving transparency and boosting customer 
                          satisfaction. Overall, centralizing all delivery operations on one platform optimizes operations and enhances customer experiences.`,
        thumbnail: tookanDms1,
        project_affiliation: 'Jungleworks',
        website: 'https://jungleworks.com/tookan/',
        responsibilities: [
            "Developed and implemented automated scripts to analyze and track weekly and monthly fluctuations in user numbers, transaction volumes, and recurring revenue.",
            "Archiving MySQL and MongoDB Servers reducing data footprint. Optimized 20+ large SQL queries and APIs, reducing processing time from seconds to milliseconds.",
            "Developed Two Factor Authentication System for additional layer of security for users to neutralize the risks associated with compromised passwords.",
            "Implemented AWS Secrets Manager to safely store all the database passwords and essential secrets which removed the vulnerability of password leaks from configuration",
            "Implemented Webhooks Retry Strategy with Exponential Backoff for essential Job update notifications.",
            "Completed 150+ Merge Requests including feature development and bug fixes."
        ],
        category: 'professional',
        tech_used: ["JavaScript", "HTML", "CSS", "Node.js", "Express.js", "MySQL", "MongoDB", "Redis", "Socket.io", "Amazon EC2", "Amazon S3"]
    },
    {
        id: 2,
        title: 'Tookan Extensions',
        shortDescription: 'An array of addon features and seamless platform integrations that elevate Tookan beyond its core capabilities, enhancing your experience and unlocking new possibilities.',
        longDescription: `Extensions on Tookan revolutionize order management and streamline delivery processes. Offering both in-house ordering capabilities and seamless integration with over 
                          45 POS systems, businesses attain comprehensive control over their operations. Tookan ensures customers enjoy swift and convenient payment options by providing a wide 
                          range of payment gateways. Moreover, businesses effortlessly maintain communication with customers through SMS gateways and WhatsApp integration, ensuring seamless 
                          interaction. With an array of add-on features such as Route Optimization, Job pricing, and earning, Task ETA, among others, coupled with seamless platform integrations, 
                          Tookan surpasses its core capabilities, enhancing the user experience and unlocking a realm of new possibilities for businesses.`,
        thumbnail: tookanExt2,
        project_affiliation: 'Jungleworks',
        responsibilities: [
            "Spearheaded the Integration Micro-Service of product Tookan, ensuring seamless functionality. Managed server maintenance, database management, code upkeep, and merge reviews to guarantee smooth integration with various third-party services.",
            "Developed custom integrations for Restaurant POS systems including Shopify, Gloria Food, Woo Commerce, Zoho Commerce, etc., Payment Gateways including Stripe, Paystack, Paypal, Razorpay, PayTM, etc., and SMS Gateways including Twilio, Plivo, Exotel, etc., and add-on features for Tookan.",
            "Revamped the Extension Page introducing new filtering options and performance improvements. Fixed the pricing issues with various add-ons for recurring billing cycle.",
        ],
        website: 'https://extensions.tookanapp.com/addons',
        category: 'professional',
        tech_used: ["JavaScript", "HTML", "CSS", "Node.js", "Express.js", "MySQL", "MongoDB", "Redis", "Socket.io", "Amazon EC2", "Amazon S3"]
    },
    {
        id: 3,
        title: 'Delivery Orchestration',
        shortDescription: `Tookan's Delivery Orchestration software seamlessly orchestrates deliveries, efficiently coordinating both third-party and in-house fleets for businesses.`,
        longDescription: `The Hybrid delivery model heralds a new era of cost reduction and expanded reach. Enables automated assignment of delivery tasks to either in-house resources or trusted 
                          third-party partners, based on availability and user-defined preferences. Dive deep into carrier performance and costing insights through Tookan's analytics dashboard, 
                          enabling informed decisions and optimized deliveries. With live tracking and real-time delivery updates from initiation to completion, elevate customer satisfaction by 
                          providing unparalleled visibility and tracking directly within the application. Prioritize delivery tasks effortlessly, whether aiming for faster delivery times or cost 
                          optimization, as tasks are intelligently auto-assigned based on preset preferences.`,
        thumbnail: tookanDo1,
        category: 'professional',
        project_affiliation: 'Jungleworks',
        responsibilities: [
            "Integrated with over 10 third-party delivery service platforms such as Borzo, Dunzo, Shadowfax, Grab, etc.",
            "Implemented a customizable priority list feature enabling users to prioritize the assignment of delivery tasks to in-house fleet resources or third-party services based on availability and prefernece.",
            "Introduced real-time task status syncing within Tookan's map view, allowing efficient tracking of deliveries without having to access partner platform.",
            "Enabled Tookan's existing clients to register as a delivery service providers to expand service offerings within the platform."
        ],
        website: 'https://jungleworks.com/tookan/delivery-orchestration-software/',
        tech_used: ["JavaScript", "HTML", "CSS", "Node.js", "Express.js", "MySQL", "MongoDB"]
    },
    {
        id: 4,
        title: 'Tookan Customer App',
        shortDescription: `Customers can easily monitor the progress of their orders using a map interface that shows the live location of the delivery agent. Furthermore, the system will 
                           seamlessly incorporate payment gateways and multiple online wallets to ensure secure transactions.`,
        longDescription: `Tookan Customer App offers customers a seamless experience throughout the delivery process. With live tracking powered by GPS technology, users can effortlessly 
                          monitor their parcel's location in real-time, ensuring they're always informed about its status. They stay updated with timely notification alerts delivered via 
                          push notifications, SMS, and email, keeping them in the loop every step of the way. Customers know exactly when to expect their delivery with accurate real-time 
                          ETA estimates for their driver. The secure in-app payment feature with a variety of payment gateways to choose from provides flexibility, allowing customers to 
                          pay conveniently using their preferred method, whether it's credit/debit cards, cash, or digital wallets. Additionally, they can share their feedback and rate 
                          their pickup/delivery experience with drivers, contributing to continuous improvement and ensuring top-notch service quality.`,
        thumbnail: tookanCust1,
        project_affiliation: 'Jungleworks',
        responsibilities: [
            "Developed scalable backend system to integrate the Customer Apps with various Payment Gateways, SMS Gateways and Social Signup Platforms.",
            "Developed complex pricing logics for task pricing and agent earning based on client requirements.",
        ],
        category: 'professional',
        website: 'https://jungleworks.com/tookan/customer-app/',
        tech_used: ["JavaScript", "HTML", "CSS", "Node.js", "Express.js", "MySQL", "MongoDB", "Redis", "Socket.io", "Amazon EC2", "Amazon S3"]
    },
    {
        id: 9,
        title: 'Book Store App',
        shortDescription: `The Book Store App lets users add, view, edit, delete, and create book entries, displaying collections in both tabular and card formats for easy management and discovery.`,
        longDescription: `The BookStore App enables users to showcase their book collections by adding, viewing, editing, deleting, and creating book entries. Users can easily manage their 
                          libraries and ensure data accuracy with these comprehensive features. The app offers two display options: a structured table format for organized overviews and a visually 
                          appealing card format for intuitive browsing. This versatility makes the BookStore App ideal for book lovers to manage their personal collections and for others to discover 
                          new and diverse reads.`,
        thumbnail: bookStore,
        project_affiliation: 'Personal Project',
        category: 'personal',
        github: 'https://github.com/saurabhjangra01/mern-book-store',
        tech_used: ["JavaScript", "Node.js", "Express.js", "MongoDB", "React.js", "Vite", "Axios", "Tailwind CSS"]
    },
    {
        id: 5,
        title: 'URL Shortener',
        shortDescription: `URL Shortener transforms lengthy URLs into compact links, tracking clicks, and facilitating seamless redirections, all while ensuring secure storage and efficient management.`,
        longDescription: `URL Shortener is a powerful tool that simplifies the sharing of URLs by converting lengthy links into concise, easy-to-share ones. Upon submission, the original 
                          URL is shortened and securely stored in the database. Clicking on the generated short URL seamlessly redirects users to the original link, while the system tracks 
                          the number of clicks for each shortened URL. With its user-friendly interface and robust functionality, URL Shortener offers a convenient solution for streamlining 
                          URL management and enhancing online engagement.`,
        thumbnail: urlShortener,
        modalImage: urlShortener2,
        project_affiliation: 'Personal Project',
        category: 'personal',
        github: 'https://github.com/saurabhjangra01/URL-Shortner',
        tech_used: ["JavaScript", "EJS", "HTML", "Bootstrap", "Node.js", "Express.js", "MongoDB"]
    },
    {
        id: 6,
        title: 'ToDo App',
        shortDescription: `ToDo List App helps you stay organized and upto date with your daily tasks by letting you easily create, manage, edit, and prioritize tasks on the go.`,
        longDescription: `The ToDo List app simplifies task management by providing a user-friendly interface to create, organize, and track your daily tasks efficiently. 
                          Whether it's jotting down quick reminders or planning long-term goals, this app ensures you stay on top of your priorities with ease.`,
        thumbnail: todo1,
        modalImage: todo2,
        project_affiliation: 'Personal Project',
        category: 'personal',
        github: 'https://github.com/saurabhjangra01/Task-Manager-App',
        tech_used: ["JavaScript", "CSS", "HTML", "Node.js", "Express.js", "MongoDB"]
    },
    {
        id: 7,
        title: 'Live Covid-19 Tracker',
        shortDescription: `Live Covid Tracker App tracks COVID-19 cases in India. Stay informed with up-to-date data on confirmed cases, recoveries, active cases, deaths.`,
        longDescription: `Live COVID-19 tracker app keeps you informed about the current situation in Indian states, providing real-time data on total confirmed cases, recoveries, 
                          active cases, and deaths. With each page reload, the app fetches the latest official data, ensuring you have access to the most accurate information available. 
                          Stay updated on the pandemic's impact with our user-friendly interface, designed to provide comprehensive insights into the evolving situation across different 
                          regions of India.`,
        thumbnail: covidTracker,
        project_affiliation: 'Personal Project',
        category: 'personal',
        github: 'https://github.com/saurabhjangra01/react-live-covid-tracker',
        tech_used: ["JavaScript", "CSS", "HTML", "React.js", "Axios"]
    },
    {
        id: 8,
        title: 'Search API',
        shortDescription: `The Search API lets users find products easily with filtering by features, company, name, price, and rating. Enjoy sorting, field selection, and pagination for a seamless search experience.`,
        longDescription: `Discover products effortlessly with the powerful search API, offering an array of filtering options including featured products, company, name, price range, 
                          and rating. With built-in sorting functionality, you can prioritize search results according to your preferences. Customize your query by selecting specific 
                          fields for the products, ensuring you receive only the relevant information you need. The Search API supports pagination, enabling smooth navigation through large 
                          datasets, ensuring an efficient and user-friendly search experience tailored to your needs.`,
        thumbnail: searchApi,
        project_affiliation: 'Personal Project',
        category: 'personal',
        github: 'https://github.com/saurabhjangra01/Store-API',
        tech_used: ["JavaScript", "Node.js", "Express.js", "MongoDB"]
    }
];

export default projects;