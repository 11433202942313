import { Link, NavLink, useLocation } from 'react-router-dom';
import './index.scss';
import LogoS from './../../assets/images/sj-1-logo-png-transparent.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';

const Navbar = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [respNavOpen, setRespNavOpen] = useState(false);
    const [inputChecked, setInputChecked] = useState(false);
    const loaction = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setRespNavOpen(false);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isMobileView = windowWidth <= 1000;

    const handleToggleClick = () => {
        setRespNavOpen(!respNavOpen);
    }

    const handleCheckboxChange = (event) => {
        setInputChecked(event.target.checked);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setRespNavOpen(false);
    }, [loaction]);

    return (
        <div className='nav-bar'>
            <Link className='logo' to='/'> {/* Link tag will make the logo as a link that can be clicked and 'to' attribute specifies the path to where the link will redirect to */}
                <img src={LogoS} alt='logo' />
            </Link>
            <nav className={respNavOpen && isMobileView ? 'mobile-view-nav' : ''}>
                <NavLink exact='true' className={({ isActive }) => (isActive ? "active" : "")} to='/' onClick={isMobileView ? handleToggleClick : null}>
                    Home
                </NavLink>
                <NavLink exact='true' className={({ isActive }) => "about-link" + (isActive ? " active" : "")} to='/about' onClick={isMobileView ? handleToggleClick : null}>
                    About
                </NavLink>
                <NavLink exact='true' activeclassname='active' className="portfolio-link" to='/portfolio' onClick={isMobileView ? handleToggleClick : null}>
                    Portfolio
                </NavLink>
                <NavLink exact='true' activeclassname='active' className="contact-link" to='/contact' onClick={isMobileView ? handleToggleClick : null}>
                    Contact
                </NavLink>
            </nav>
            {isMobileView && (
                <div className='nav-toggle'>
                    <input type='checkbox' checked={respNavOpen} onClick={handleToggleClick} onChange={handleCheckboxChange} />
                    <span></span>
                    <span></span>
                    <span></span>
                    {/* <button onClick={handleToggleClick}>O</button> */}
                </div>
            )}
            <ul>
                <li>
                    <Tooltip
                        slotProps={{
                            popper: { modifiers: [{ name: 'offset', options: { offset: [0, -6] } }] },
                            tooltip: { sx: { fontSize: '1em' } }
                        }}
                        title={<span style={{ letterSpacing: "1px" }}>LinkedIn</span>}
                    >
                        <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/in/saurabh-jangra'>
                            <FontAwesomeIcon icon={faLinkedin} color='#4d4d4e' />
                        </a>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip
                        slotProps={{
                            popper: { modifiers: [{ name: 'offset', options: { offset: [0, -6] } }] },
                            tooltip: { sx: { fontSize: '1em' } }
                        }}
                        title={<span style={{ letterSpacing: "1px" }}>Github</span>}
                    >
                        <a target='_blank' rel='noreferrer' href='https://github.com/saurabhjangra01'>
                            <FontAwesomeIcon icon={faGithub} color='#4d4d4e' />
                        </a>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip
                        slotProps={{
                            popper: { modifiers: [{ name: 'offset', options: { offset: [0, -6] } }] },
                            tooltip: { sx: { fontSize: '1em' } }
                        }}
                        title={<span style={{ letterSpacing: "1px" }}>Instagram</span>}
                    >
                        <a target='_blank' rel='noreferrer' href='https://www.instagram.com/_saurabhjangra_/'>
                            <FontAwesomeIcon icon={faInstagram} color='#4d4d4e' />
                        </a>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip
                        slotProps={{
                            popper: { modifiers: [{ name: 'offset', options: { offset: [0, -6] } }] },
                            tooltip: { sx: { fontSize: '1em' } }
                        }}
                        title={<span style={{ letterSpacing: "1px" }}>LinkedIn</span>}
                    >
                        <a target='_blank' rel='noreferrer' href='https://twitter.com/saurabhjangra01'>
                            <FontAwesomeIcon icon={faTwitter} color='#4d4d4e' />
                        </a>
                    </Tooltip>
                </li>
            </ul>
        </div>
    );
}

export default Navbar;