import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useForm, ValidationError } from '@formspree/react';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [state, handleSubmit] = useForm("xkndydyz");

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    useEffect(() => {
        if (state.succeeded) {
            setFormData({
                name: '',
                email: '',
                message: ''
            });

            setShowSuccessMsg(true);

            const timer = setTimeout(() => {
                setShowSuccessMsg(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [state.succeeded]);

    return (
        <div className="container contact-page">
            <div className='sections'>
                <div className="row">
                    <div className="col contact-details-resp">
                        <h2>Let's get in touch</h2>
                        <h3>Location:</h3>
                        <address>Delhi, India</address>
                        <h3>Call:</h3>
                        <p>+91 81783 81808</p>
                        <h3 className='socials'>Or reach out to me via:</h3>
                        <ul>
                            <li>
                                <Tooltip
                                    slotProps={{
                                        popper: { modifiers: [{ name: 'offset', options: { offset: [0, -6] } }] },
                                        tooltip: { sx: { fontSize: '1em' } }
                                    }}
                                    title={<span style={{ letterSpacing: "1px" }}>LinkedIn</span>}
                                >
                                    <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/in/saurabh-jangra'>
                                        <FontAwesomeIcon icon={faLinkedin} color='#4d4d4e' />
                                    </a>
                                </Tooltip>
                            </li>
                            <li>
                                <Tooltip
                                    slotProps={{
                                        popper: { modifiers: [{ name: 'offset', options: { offset: [0, -6] } }] },
                                        tooltip: { sx: { fontSize: '1em' } }
                                    }}
                                    title={<span style={{ letterSpacing: "1px" }}>Github</span>}
                                >
                                    <a target='_blank' rel='noreferrer' href='https://github.com/saurabhjangra01'>
                                        <FontAwesomeIcon icon={faGithub} color='#4d4d4e' />
                                    </a>
                                </Tooltip>
                            </li>
                            <li>
                                <Tooltip
                                    slotProps={{
                                        popper: { modifiers: [{ name: 'offset', options: { offset: [0, -6] } }] },
                                        tooltip: { sx: { fontSize: '1em' } }
                                    }}
                                    title={<span style={{ letterSpacing: "1px" }}>Instagram</span>}
                                >
                                    <a target='_blank' rel='noreferrer' href='https://www.instagram.com/_saurabhjangra_/'>
                                        <FontAwesomeIcon icon={faInstagram} color='#4d4d4e' />
                                    </a>
                                </Tooltip>
                            </li>
                            <li>
                                <Tooltip
                                    slotProps={{
                                        popper: { modifiers: [{ name: 'offset', options: { offset: [0, -6] } }] },
                                        tooltip: { sx: { fontSize: '1em' } }
                                    }}
                                    title={<span style={{ letterSpacing: "1px" }}>Twitter</span>}
                                >
                                    <a target='_blank' rel='noreferrer' href='https://twitter.com/saurabhjangra01'>
                                        <FontAwesomeIcon icon={faTwitter} color='#4d4d4e' />
                                    </a>
                                </Tooltip>
                            </li>
                        </ul>
                    </div>
                    <hr />
                    <div className="col">
                        <form id='contact-form' className='form-container' onSubmit={handleSubmit}>
                            <div className='col-form'>
                                <div className='row-form'>
                                    <label className='form-label' htmlFor='name'>
                                        Your Name:
                                    </label>
                                    <input
                                        id='name'
                                        name='name'
                                        type='text'
                                        className='form-input'
                                        autoComplete='off'
                                        value={formData.name}
                                        disabled={state.submitting}
                                        onChange={handleInputChange}
                                    />
                                    <ValidationError prefix="Name" field="name" errors={state.errors} style={{ color: 'red', marginTop: '8px', fontSize: '14px', fontWeight: '500' }} />
                                </div>
                                <div className='row-form'>
                                    <label className='form-label' htmlFor='email'>
                                        Your Email Address:
                                    </label>
                                    <input
                                        id='email'
                                        name='email'
                                        type='email'
                                        className='form-input'
                                        value={formData.email}
                                        disabled={state.submitting}
                                        onChange={handleInputChange}
                                    />
                                    <ValidationError prefix="Email" field="email" errors={state.errors} style={{ color: 'red', marginTop: '8px', fontSize: '14px', fontWeight: '500' }} />
                                </div>
                                <div className='row-form'>
                                    <label className='form-label' htmlFor='message'>
                                        Message:
                                    </label>
                                    <textarea
                                        id='message'
                                        className='form-input'
                                        name='message'
                                        rows='4'
                                        spellCheck='false'
                                        value={formData.message}
                                        disabled={state.submitting}
                                        onChange={handleInputChange}
                                    />
                                    <ValidationError prefix="Message" field="message" errors={state.errors} style={{ color: 'red', marginTop: '8px', fontSize: '14px', fontWeight: '500' }} />
                                </div>
                                <div className='row-form submit-container'>
                                    <button
                                        id='submit-btn'
                                        className='btn'
                                        disabled={!(formData.name && formData.email && formData.message) || state.submitting}
                                    >
                                        Send
                                    </button>
                                    {showSuccessMsg && <p style={{ color: 'green', marginLeft: '20px', fontSize: '14px', fontWeight: '500' }}>Your message has been sent!</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;