import './index.scss';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faUpRightFromSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import { useEffect } from 'react';

const ProjectModal = ({ onClose, project }) => {
    const handleKeyPress = (e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        }
    }, [onClose]);

    return (
        <div
            className="project-modal"
            onClick={onClose}
        >
            <div
                className="modal-content"
                onClick={e => e.stopPropagation()}
            >
                <div className='modal-header'>
                    <span className="close-button" onClick={onClose}><FontAwesomeIcon icon={faXmark} /></span>
                    <h2>{project.title || "Project"}</h2>
                </div>
                <div className='flex-row'>
                    <div className="image-carousel">
                        <img src={project.modalImage || project.thumbnail} alt="Project Thumbnail" />
                    </div>
                    <div className='project-details'>
                        <h4>Project Info:</h4>
                        <p>{project.longDescription || project.shortDescription}</p>
                        <h4>Project Affiliation:</h4>
                        <p>{project.project_affiliation}</p>
                        <h4>Technology Stack:</h4>
                        <p>{project.tech_used?.join(", ")}</p>
                        {project.responsibilities && project.responsibilities.length && (
                            <div>
                                <h4>My Contributions:</h4>
                                <ul>
                                    {project.responsibilities.map((item, index) => <li key={index}>{item}</li>)}
                                </ul>
                            </div>
                        )}

                        <div className="options">
                            {project.website && (
                                <Tooltip
                                    slotProps={{
                                        popper: { modifiers: [{ name: 'offset', options: { offset: [0, -4] } }] },
                                        tooltip: { sx: { fontSize: '1em' } }
                                    }}
                                    title={<span style={{ letterSpacing: "1px", fontWeight: 500 }}>Go to Project Site</span>}
                                    arrow
                                >
                                    <a target='_blank' rel='noreferrer' href={project.website}>
                                        <FontAwesomeIcon icon={faUpRightFromSquare} />
                                    </a>
                                </Tooltip>
                            )}
                            {project.github && (
                                <Tooltip
                                    slotProps={{
                                        popper: { modifiers: [{ name: 'offset', options: { offset: [0, -4] } }] },
                                        tooltip: { sx: { fontSize: '1em' } }
                                    }}
                                    title={<span style={{ letterSpacing: "1px", fontWeight: 500 }}>Go to Github Repository</span>}
                                    arrow
                                >
                                    <a target='_blank' rel='noreferrer' href={project.github}>
                                        <FontAwesomeIcon icon={faGithub} style={{ fontSize: '2.8rem' }} color='#1f2328' />
                                    </a>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectModal;
