import './index.scss';
import { useState } from 'react';
import ProjectCard from './../ProjectCard';
import ProjectModal from './../ProjectModal';
import projects from './projectsList';

const Portfolio = () => {
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [filteredProjects, setFilteredProjects] = useState(projects);

    const handleFilterClick = (filter) => {
        setSelectedFilter(filter);

        const nextFilteredProjects = projects.filter(project => {
            return filter === 'all' || project.category === filter;
        });

        setFilteredProjects(nextFilteredProjects);
    }

    return (
        <div className="container portfolio-page">
            <div className="sections">
                <div className="portfolio-projects">
                    <p className='sub-heading-a'>Portfolio</p>
                    <h1 className='sub-heading-b'>My most recent Projects</h1>

                    <ul className='filter-options'>
                        <li className='nav-item'>
                            <NavBtn
                                text={"All"}
                                filterType={"all"}
                                currentFilter={selectedFilter}
                                onFilterClick={handleFilterClick}
                            />
                        </li>
                        <li className='nav-item'>
                            <NavBtn
                                text={"Product Contributions"}
                                filterType={"professional"}
                                currentFilter={selectedFilter}
                                onFilterClick={handleFilterClick}
                            />
                        </li>
                        <li className='nav-item'>
                            <NavBtn
                                text={"Personal Projects"}
                                filterType={"personal"}
                                currentFilter={selectedFilter}
                                onFilterClick={handleFilterClick}
                            />
                        </li>
                    </ul>

                    <div className='project-cards'>
                        {filteredProjects.map((project) => {
                            return <ProjectCard
                                key={project.id}
                                project={project}
                            />
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Portfolio;

const NavBtn = ({ text, onFilterClick, filterType, currentFilter }) => {
    return (
        <>
            <button
                id='nav-btn'
                className={currentFilter === filterType ? 'active' : ''}
                onClick={() => onFilterClick(filterType)}
            >{text}</button>
        </>
    );
}