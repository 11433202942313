import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-router-dom';
import MyImage from './../../assets/images/saurabh.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEnvelope, faLocationDot, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import './index.scss';

const Home = () => {
    return (
        <div className="container home-page">
            <div className='top-sec'>
                <div className="text-zone">
                    <div className='greet-name'>Hi, I'm Saurabh Jangra</div>
                    <div className='animated-text-container'>
                        <TypeAnimation
                            className='animated-text'
                            sequence={['Software Engineer', 1000, 'Full Stack Developer', 1000]}
                            repeat={Infinity}
                        />
                    </div>
                    <ul className='contact-info'>
                        <li>
                            <Tooltip
                                slotProps={{
                                    popper: { modifiers: [{ name: 'offset', options: { offset: [0, 14] } }] },
                                    tooltip: { sx: { fontSize: '1em' } }
                                }}
                                title={<span style={{ letterSpacing: "1px" }}>Click to send Email</span>}
                                followCursor
                            >
                                <a href='https://mail.google.com/mail/?view=cm&fs=1&to=saurabhjangra96@gmail.com' target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' icon={faEnvelope} />saurabhjangra96@gmail.com</a>
                            </Tooltip>
                        </li>
                        <li><FontAwesomeIcon className='icon' icon={faMobileAlt} />+91 81783 81808</li>
                        <li><FontAwesomeIcon className='icon' icon={faLocationDot} />Delhi, India</li>
                    </ul>
                    <div className='buttons'>
                        <a className='resume-btn' href="/Saurabh Jangra.pdf" download="Saurabh Jangra.pdf"><FontAwesomeIcon className='download-icon' icon={faDownload} />
                            Resume
                        </a>
                        <Link className='contact-btn' to='/contact'>
                            Get In Touch
                        </Link>
                    </div>
                </div>
                <div className="image-zone">
                    <img src={MyImage} alt='Saurabh Jangra' />
                </div>
            </div>
            <div className='bottom-sec'>
            </div>
        </div>
    );
}

export default Home;