import './index.scss';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faCircleInfo, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import ProjectModal from '../ProjectModal';
import { useState } from 'react';

const ProjectCard = ({ project }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <div className="project-card">
                <div className='project-content'>
                    <h3>{project.title}</h3>
                    <img src={project.thumbnail} alt={project.title} />
                    <div className='tech-stack'>
                        {project.tech_used.map((tech, index) => (
                            <span
                                key={index}
                                className='tech-item'
                            >
                                {tech}
                            </span>
                        ))}
                    </div>
                    <p>{project.shortDescription}</p>
                </div>
                <div className="options">
                    {project.website && (
                        <Tooltip
                            slotProps={{
                                popper: { modifiers: [{ name: 'offset', options: { offset: [0, -4] } }] },
                                tooltip: { sx: { fontSize: '1em' } }
                            }}
                            title={<span style={{ letterSpacing: "1px", fontWeight: 500 }}>Go to Project Site</span>}
                            placement='top-end'
                            arrow
                        >
                            <a target='_blank' rel='noreferrer' href={project.website}>
                                <FontAwesomeIcon icon={faUpRightFromSquare} />
                            </a>
                        </Tooltip>
                    )}
                    {project.github && (
                        <Tooltip
                            slotProps={{
                                popper: { modifiers: [{ name: 'offset', options: { offset: [0, -4] } }] },
                                tooltip: { sx: { fontSize: '1em' } }
                            }}
                            title={<span style={{ letterSpacing: "1px", fontWeight: 500 }}>Go to Github Repository</span>}
                            placement='top-end'
                            arrow
                        >
                            <a target='_blank' rel='noreferrer' href={project.github}>
                                <FontAwesomeIcon icon={faGithub} style={{ fontSize: '2.6rem' }} color='#1f2328' />
                            </a>
                        </Tooltip>
                    )}
                    <Tooltip
                        slotProps={{
                            popper: { modifiers: [{ name: 'offset', options: { offset: [0, -4] } }] },
                            tooltip: { sx: { fontSize: '1em' } }
                        }}
                        title={<span style={{ letterSpacing: "1px", fontWeight: 500 }}>More Details</span>}
                        placement='top-end'
                        arrow
                    >
                        <button onClick={() => setShowModal(true)}>
                            <FontAwesomeIcon icon={faCircleInfo} />
                        </button>
                    </Tooltip>
                </div>
            </div >
            {showModal && (
                <ProjectModal
                    project={project}
                    onClose={() => setShowModal(false)}
                />
            )
            }
        </>
    );
};

export default ProjectCard;
