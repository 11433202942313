import './index.scss';
import { LinearProgress, createTheme, ThemeProvider } from '@mui/material';
import contactBgImg from './../../assets/images/intro-bg-2.jpg';
import { Link } from 'react-router-dom';


const About = () => {
    return (
        <div className="container about-page">
            <div className='sections'>
                <div className='about-me'>
                    <p className='sub-heading-a'>About Me</p>
                    <h1 className='sub-heading-b'>Know Me More</h1>
                    <div className='about-me-container'>
                        <div className='experience'>
                            <div className='summary'>
                                <h2>Hi, I'm <span>Saurabh</span></h2>
                                <p>An experienced Software Engineer who brings a dynamic blend of software development prowess, data analysis finesse, and adept project management skills to the table.
                                    With a solid foundation in <span>Javascript, Node.js, Express.js, React.js, HTML, CSS, MySQL, MongoDB, Git, Microservices, REST APIs and various other technologies</span>, I have a proven
                                    track record of spearheading successful projects from inception to execution. With a passion for innovation and a commitment to delivering top-tier solutions, I continuously strive to push
                                    boundaries and drive impactful outcomes.</p>
                                {/* <p>An experienced Software Engineer who brings a dynamic blend of software development prowess, data analysis finesse, and adept project management skills to the table.
                                    With a solid foundation in <span>Javascript, Node.js, Express.js, React.js, HTML, CSS, MySQL, MongoDB, Git, Microservices, REST APIs and various other technologies</span>, I have a proven
                                    track record of spearheading successful projects from inception to execution. My approach is deeply rooted in understanding user requirements and implementing scalable solutions,
                                    complemented by proficiency in development and database management. With a passion for innovation and a commitment to delivering top-tier solutions, I continuously strive to push
                                    boundaries and drive impactful outcomes.</p> */}
                            </div>
                            <div className='yoe'>
                                <h1>{((new Date() - new Date('2022-01-01')) / (1000 * 60 * 60 * 24 * 365)).toFixed(1)}</h1>
                                <h3>Years of <span>Experience</span></h3>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <p>Name:</p>
                                <h4>Saurabh Jangra</h4>
                            </li>
                            <li>
                                <p>Email:</p>
                                <h4><a href='https://mail.google.com/mail/?view=cm&fs=1&to=saurabhjangra96@gmail.com' target='_blank' rel='noreferrer'>saurabhjangra96@gmail.com</a></h4>
                            </li>
                            <li>
                                <p>Date of Birth:</p>
                                <h4>12 June, 2001</h4>
                            </li>
                            <li>
                                <p>From:</p>
                                <h4>Delhi, India</h4>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='resume'>
                    <p className='sub-heading-a'>Resume</p>
                    <h1 className='sub-heading-b'>A summary of my Resume</h1>
                    <div className='work-exp'>
                        <div className='education'>
                            <h1>Education</h1>
                            <div className='items'>
                                <ExpCard head1={"Bachelor of Engineering"} head2={"Netaji Subhas Institute of Technology, Delhi"} description={[]} timeline={"2018 - 2022"} />
                                <ExpCard head1={"Grade 12 (Sr. Secondary Examination)"} head2={"Central Board of Secondary Education, Delhi"} description={[]} timeline={"2017- 2018"} />
                                <ExpCard head1={"Grade 10 (Secondary Examination)"} head2={"Central Board of Secondary Education, Delhi"} description={[]} timeline={"2015 - 2016"} />
                            </div>
                        </div>
                        <div className='experience'>
                            <h1>Experience</h1>
                            <div className='items'>
                                <ExpCard head1={"Associate Software Engineer"} head2={"Jungleworks, Chandigarh"} timeline={"Jul 2022 - Present"} description={[
                                    "Completed 150+ merge requests, covering feature development, optimizations, and bug fixes for Tookan Product.",
                                    "Implemented database and server-side optimizations, reducing processing time from seconds to milliseconds.",
                                    "Optimized and Archived MySQL and MongoDB Servers, cutting data footprint by over 70%.",
                                    "Developed custom integrations for Restaurant POS System, Third-party Delivery Services, Payment Gateways, SMS Gateways, and Payouts Systems.",
                                    "Developed automated scripts for analyzing and tracking user numbers, transaction volumes, and revenue fluctuations."
                                ]} />
                                <ExpCard head1={"Software Engineering Intern"} head2={"Jungleworks, Chandigarh"} timeline={"Jan 2022 - June 2022"} description={[
                                    "Developed and implemented Rest-APIs to enhance functionality, and developed new features for Tookan Product.",
                                    "Integrated API with various 3rd party services including Payment Gateways and SMS Gateways.",
                                    "Made improvements in the existing code architecture, leading to reduction in system crashes and improved overall system performance."
                                ]} />
                            </div>
                        </div>
                    </div>

                    <div className='skills'>
                        <h1>My Skills</h1>
                        <div className='skills-list'>
                            <div className='skills-sec'>
                                <SkillCard name="Node.js" percentage={90} />
                                <SkillCard name="Javascript" percentage={80} />
                                <SkillCard name="Express.js" percentage={80} />
                                <SkillCard name="Java" percentage={80} />
                                <SkillCard name="MySQL" percentage={80} />
                                <SkillCard name="MongoDB" percentage={75} />
                                <SkillCard name="Redis" percentage={75} />
                                <SkillCard name="REST API" percentage={90} />
                            </div>
                            <div className='skills-sec'>
                                <SkillCard name="HTML" percentage={80} />
                                <SkillCard name="CSS" percentage={75} />
                                <SkillCard name="React JS" percentage={75} />
                                <SkillCard name="Git" percentage={80} />
                                <SkillCard name="OOPs" percentage={80} />
                                <SkillCard name="Data Structures" percentage={75} />
                                <SkillCard name="Algorithms" percentage={75} />
                                <SkillCard name="Database Management" percentage={80} />
                            </div>
                        </div>
                    </div>
                    <a className='resume-btn' href="/Saurabh Jangra.pdf" download="Saurabh Jangra.pdf">
                        Download CV
                    </a>
                </div>
                <div className='contact-container'>
                    <div className='overlay'></div>
                    <img src={contactBgImg} alt='' />
                    <div className='content'>
                        <h1>Interested in working with me?</h1>
                        <a className='hire-btn' href='https://forms.gle/7vGNUG2Ws3KbHMwK9' target='_blank' rel='noreferrer'>Hire Me!</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;

const theme = createTheme({
    palette: {
        yellow: {
            main: '#f8de53'
        },

        background: {
            main: '#e9ecef'
        }
    },
});

const SkillCard = ({ name, percentage }) => {
    return (
        <>
            <div className='card-container'>
                <div>
                    <h4>{name}</h4>
                    <span>{`${percentage}%`}</span>
                </div>
                <ThemeProvider theme={theme}>
                    <LinearProgress
                        variant="determinate"
                        color='yellow'
                        sx={{
                            bgcolor: 'background.main',
                            height: 8,
                            borderRadius: 5
                        }}
                        value={percentage} />
                </ThemeProvider>
            </div>
        </>
    );
}

const ExpCard = ({ head1, head2, timeline, description }) => {
    return (
        <>
            <div className='exp-card-container'>
                <h2>{head1}</h2>
                <div className='head2'>
                    <h3>{head2}</h3>
                    <h3>{timeline}</h3>
                </div>
                <ul className='desc'>
                    {description.map((point, index) => { return <li key={index}> {point} </li> })}
                </ul>
                <hr />
            </div>
        </>
    );
}